import moment from "moment";

var hours = ['12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am', '8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm', '4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm'];
var days = ['Saturday', 'Friday', 'Thursday', 'Wednesday', 'Tuesday', 'Monday', 'Sunday'];
var data = [
  [0, 0, 15],
  [0, 1, 11],
  [0, 2, 6],
  [0, 3, 16],
  [0, 4, 14],
  [0, 5, 10],
  [0, 6, 4],
  [0, 7, 12],
  [0, 8, 4],
  [0, 9, 20],
  [0, 10, 17],
  [0, 11, 2],
  [0, 12, 4],
  [0, 13, 1],
  [0, 14, 1],
  [0, 15, 3],
  [0, 16, 4],
  [0, 17, 6],
  [0, 18, 4],
  [0, 19, 4],
  [0, 20, 3],
  [0, 21, 3],
  [0, 22, 2],
  [0, 23, 5],
  [1, 0, 7],
  [1, 1, 10],
  [1, 2, 9],
  [1, 3, 17],
  [1, 4, 8],
  [1, 5, 4],
  [1, 6, 7],
  [1, 7, 2],
  [1, 8, 0],
  [1, 9, 0],
  [1, 10, 5],
  [1, 11, 2],
  [1, 12, 2],
  [1, 13, 6],
  [1, 14, 9],
  [1, 15, 11],
  [1, 16, 6],
  [1, 17, 7],
  [1, 18, 8],
  [1, 19, 12],
  [1, 20, 5],
  [1, 21, 5],
  [1, 22, 7],
  [1, 23, 2],
  [2, 0, 1],
  [2, 1, 1],
  [2, 2, 8],
  [2, 3, 10],
  [2, 4, 6],
  [2, 5, 4],
  [2, 6, 12],
  [2, 7, 20],
  [2, 8, 16],
  [2, 9, 10],
  [2, 10, 3],
  [2, 11, 2],
  [2, 12, 1],
  [2, 13, 9],
  [2, 14, 8],
  [2, 15, 10],
  [2, 16, 6],
  [2, 17, 5],
  [2, 18, 5],
  [2, 19, 5],
  [2, 20, 7],
  [2, 21, 4],
  [2, 22, 2],
  [2, 23, 4],
  [3, 0, 7],
  [3, 1, 3],
  [3, 2, 10],
  [3, 3, 0],
  [3, 4, 4],
  [3, 5, 0],
  [3, 6, 0],
  [3, 7, 4],
  [3, 8, 1],
  [3, 9, 0],
  [3, 10, 5],
  [3, 11, 4],
  [3, 12, 7],
  [3, 13, 14],
  [3, 14, 13],
  [3, 15, 12],
  [3, 16, 9],
  [3, 17, 5],
  [3, 18, 5],
  [3, 19, 10],
  [3, 20, 6],
  [3, 21, 4],
  [3, 22, 4],
  [3, 23, 1],
  [4, 0, 1],
  [4, 1, 3],
  [4, 2, 2],
  [4, 3, 6],
  [4, 4, 3],
  [4, 5, 1],
  [4, 6, 7],
  [4, 7, 16],
  [4, 8, 10],
  [4, 9, 2],
  [4, 10, 4],
  [4, 11, 4],
  [4, 12, 2],
  [4, 13, 4],
  [4, 14, 4],
  [4, 15, 14],
  [4, 16, 12],
  [4, 17, 1],
  [4, 18, 8],
  [4, 19, 5],
  [4, 20, 3],
  [4, 21, 7],
  [4, 22, 3],
  [4, 23, 0],
  [5, 0, 2],
  [5, 1, 1],
  [5, 2, 9],
  [5, 3, 3],
  [5, 4, 0],
  [5, 5, 0],
  [5, 6, 0],
  [5, 7, 0],
  [5, 8, 2],
  [5, 9, 0],
  [5, 10, 4],
  [5, 11, 1],
  [5, 12, 5],
  [5, 13, 10],
  [5, 14, 5],
  [5, 15, 7],
  [5, 16, 11],
  [5, 17, 6],
  [5, 18, 0],
  [5, 19, 5],
  [5, 20, 3],
  [5, 21, 4],
  [5, 22, 2],
  [5, 23, 0],
  [6, 0, 1],
  [6, 1, 0],
  [6, 2, 4],
  [6, 3, 0],
  [6, 4, 0],
  [6, 5, 6],
  [6, 6, 0],
  [6, 7, 15],
  [6, 8, 0],
  [6, 9, 0],
  [6, 10, 1],
  [6, 11, 0],
  [6, 12, 2],
  [6, 13, 1],
  [6, 14, 3],
  [6, 15, 4],
  [6, 16, 10],
  [6, 17, 0],
  [6, 18, 0],
  [6, 19, 0],
  [6, 20, 1],
  [6, 21, 2],
  [6, 22, 2],
  [6, 23, 6]
];
data = data.map(function (item) {
  return [item[1], item[0], item[2]];
});
const chartDatas = {
  sentimentPieOptions: {
    color: ['#66bb6a', '#ef5350', '#29b6f6'],
    tooltip: {
      trigger: 'item'
    },
    // Global text styles
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },
    series: [{
      emphasis: {
        scale: false,
        focus: 'self',
      },
      itemStyle: {
        borderWidth: 2,
        borderColor: '#fff',
      },
      type: 'pie',
      radius: ['50%', '100%'],
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },

      // Add tooltip
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.75)',
        padding: [10, 15],
        textStyle: {
          fontSize: 13,
          fontFamily: 'Roboto, sans-serif',
          color: '#fff'
        },
      },
      data: [{
          value: 300,
          name: 'Positive Sentiments'
        },
        {
          value: 250,
          name: 'Negative Sentiments'
        },
        {
          value: 174,
          name: 'Neutral Sentiments'
        },
      ]
    }]
  },
  mediaPieOptions: {
    color: ['#66bb6a', '#ef5350'],
    // Global text styles
    // Add tooltip
    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: '#fff'
      },
    },
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },
    series: [{
      emphasis: {
        scale: false,
        focus: 'self',
      },
      itemStyle: {
        borderWidth: 2,
        borderColor: '#fff',
      },
      type: 'pie',
      radius: ['50%', '100%'],
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },

      data: [{
          value: 300,
          name: 'Website'
        },
        {
          value: 250,
          name: 'Media Sosial'
        },
      ]
    }]
  },
  //-----End Sentimern Option-----
  mentionSummaryOptions: {
    // Define colors
    color: ["#2980b9", "#27ae60"],
    // Global text styles
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },
    // Chart animation duration
    animationDuration: 750,
    // Setup grid
    grid: {
      left: 0,
      right: 40,
      top: 35,
      bottom: 60,
      containLabel: true
    },

    // Add legend
    legend: {
      data: ['Social Media', 'Web'],
      itemHeight: 8,
      itemGap: 20
    },

    // Add tooltip
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: '#fff'
      },
    },

    // Horizontal axis
    xAxis: [{
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        color: '#333',
        formatter: function (value) {
          return moment(value).locale('id').format('ll')
        }
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      data: [
        '2021-08-26', '2021-09-03', '2021-09-10', '2021-09-29', '2021-10-04', '2021-10-07', '2021-10-08', '2021-10-14', '2021-11-01', '2021-11-04', '2021-11-11', '2021-11-19', '2021-11-22', '2021-11-24', '2021-11-25', '2021-11-29', '2021-11-30', '2021-12-01', '2021-12-08', '2021-12-09', '2021-12-15', '2021-12-16', '2021-12-17', '2021-12-27', '2021-12-31',
      ]
    }],

    // Vertical axis
    yAxis: [{
      type: 'value',
      axisLabel: {
        formatter: '{value} ',
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        lineStyle: {
          color: ['#eee']
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
        }
      }
    }],

    // Zoom control
    dataZoom: [{
        type: 'inside',
        start: 30,
        end: 70
      },
      {
        show: true,
        type: 'slider',
        start: 30,
        end: 70,
        height: 40,
        bottom: 0,
        borderColor: '#ccc',
        fillerColor: 'rgba(0,0,0,0.05)',
        handleStyle: {
          color: '#585f63'
        }
      }
    ],

    // Add series
    series: [{
        name: 'Social Media',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        },
        data: [152, 156, 479, 442, 654, 835, 465, 704, 643, 136, 791, 254, 688, 119, 948, 316, 612, 378, 707,
          404, 485, 226, 754, 142, 965, 364, 887, 395, 838, 113, 662
        ]
      },
      {
        name: 'Web',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        },
        data: [677, 907, 663, 137, 952, 408, 976, 772, 514, 102, 165, 343, 374, 744, 237, 662, 875, 462, 409,
          259, 396, 744, 359, 618, 127, 596, 161, 574, 107, 914, 708
        ]
      }
    ]
  },
  //-----End Mention Summary Option-----
  mentionLocationOptions: {
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },
    animationDuration: 750,
    grid: {
      left: 0,
      right: 30,
      top: 35,
      bottom: 0,
      containLabel: true
    },
    legend: {
      data: ['Internet Explorer'],
      itemHeight: 8,
      itemGap: 20,
      textStyle: {
        padding: [0, 5]
      }
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: '#fff',
      },
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          color: 'rgba(0,0,0,0.025)'
        }
      }
    },
    xAxis: [{
      type: 'value',
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#eee',
          type: 'dashed'
        }
      }
    }],
    yAxis: [{
      type: 'category',
      data: ['Jakarta', 'Bandung', 'Tangerang', 'Bekasi', 'Bogor', 'Depok', 'Surabaya'],
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eee']
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.015)']
        }
      }
    }],

    // Add series
    series: [{
      name: 'Chrome',
      type: 'bar',
      stack: 'Total',
      itemStyle: {
        normal: {
          color: '#5C6BC0',
          label: {
            show: true,
            position: 'insideRight',
            padding: [0, 10],
            fontSize: 12
          }
        }
      },
      data: [820, 832, 901, 934, 1290, 1330, 1320]
    }]
  },
 //-----End Mention Local Option-----
  mentionSourceOptions: {
    // Colors
    color: [
      '#2F4EA8', '#A62B85', '#1B9FF1', '#BF2525', '#F6A82C', '#8d98b3'
    ],
    // Global text styles
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13,
    },
    // Add tooltip
    tooltip: {
      trigger: 'item',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: '#fff',
      },
      formatter: "{a} <br/>{b}: {c} ({d}%)"
    },

    // Add legend
    legend: {
      orient: 'vertical',
      top: 'center',
      left: 0,
      data: ['Facebook', 'Instagram', 'Twitter', 'YouTube', 'Blog', 'Web'],
      itemHeight: 8,
      itemWidth: 8
    },

    // Add series
    series: [{
      name: 'Browsers',
      type: 'pie',
      radius: '70%',
      center: ['50%', '57.5%'],
      itemStyle: {
        normal: {
          borderWidth: 1,
          borderColor: '#fff'
        }
      },
      data: [{
          value: 335,
          name: 'Facebook'
        },
        {
          value: 310,
          name: 'Instagram'
        },
        {
          value: 234,
          name: 'Twitter'
        },
        {
          value: 135,
          name: 'YouTube'
        },
        {
          value: 135,
          name: 'Blog'
        },
        {
          value: 1548,
          name: 'Web'
        }
      ]
    }]
  },
   //-----End Mention Source Option-----
  socmedFollowerOptions: {

    // Define colors
    color: ['#A62B85', '#1996E3', '#2F4EA8'],

    // Global text styles
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },

    // Chart animation duration
    animationDuration: 750,

    // Setup grid
    grid: {
      left: 0,
      right: 40,
      top: 35,
      bottom: 0,
      containLabel: true
    },

    // Add legend
    legend: {
      data: ['Instagram', 'Twitter', 'Facebook'],
      itemHeight: 8,
      itemGap: 20
    },

    // Add tooltip
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: '#fff',
      }
    },

    // Horizontal axis
    xAxis: [{
      type: 'category',
      boundaryGap: false,
      data: ['20 Jun', '19 Jun', '18 Jun', '17 Jun', '16 Jun', '15 Jun', '14 Jun'],
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#eee',
          type: 'dashed'
        }
      }
    }],

    // Vertical axis
    yAxis: [{
      type: 'value',
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        lineStyle: {
          color: '#eee'
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
        }
      }
    }],

    // Add series
    series: [{
        name: 'Instagram',
        type: 'line',
        stack: 'Total',
        areaStyle: {
          normal: {
            opacity: 0.25
          }
        },
        smooth: true,
        symbolSize: 7,
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        },
        data: [120, 132, 101, 134, 490, 230, 210]
      },
      {
        name: 'Twitter',
        type: 'line',
        stack: 'Total',
        areaStyle: {
          normal: {
            opacity: 0.25
          }
        },
        smooth: true,
        symbolSize: 7,
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        },
        data: [150, 1232, 901, 154, 190, 330, 810]
      },
      {
        name: 'Facebook',
        type: 'line',
        stack: 'Total',
        areaStyle: {
          normal: {
            opacity: 0.25
          }
        },
        smooth: true,
        symbolSize: 7,
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        },
        data: [320, 1332, 1801, 1334, 590, 830, 1220]
      }
    ]
  },
  //-----End Socmed Followe Option-----
  leadsOptions: {

    // Global text styles
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },

    // Chart animation duration
    animationDuration: 750,

    // Setup grid
    grid: {
      left: 0,
      right: 20,
      top: 35,
      bottom: 0,
      containLabel: true
    },

    // Add legend
    legend: {
      data: ['Hot', 'Medium', 'Cold', 'Default'],
      itemHeight: 8,
      itemGap: 20
    },

    // Add tooltip
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: '#fff',
      }
    },

    // Horizontal axis
    xAxis: [{
      type: 'category',
      boundaryGap: false,
      data: [
        'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'
      ],
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        lineStyle: {
          color: ['#eee']
        }
      }
    }],

    // Vertical axis
    yAxis: [{
      type: 'value',
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        lineStyle: {
          color: ['#eee']
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
        }
      }
    }],

    // Add series
    series: [{
        name: 'Hot',
        type: 'line',
        stack: 'Total',
        smooth: true,
        symbolSize: 7,
        data: [120, 132, 101, 134, 90, 230, 210, 101, 134, 90, 230, 210],
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        }
      },
      {
        name: 'Medium',
        type: 'line',
        stack: 'Total',
        smooth: true,
        symbolSize: 7,
        data: [220, 182, 191, 234, 290, 330, 310, 191, 234, 290, 330, 310],
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        }
      },
      {
        name: 'Cold',
        type: 'line',
        stack: 'Total',
        smooth: true,
        symbolSize: 7,
        data: [150, 232, 201, 154, 190, 330, 410, 201, 154, 190, 330, 410],
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        }
      },
      {
        name: 'Default',
        type: 'line',
        stack: 'Total',
        smooth: true,
        symbolSize: 7,
        data: [320, 332, 301, 334, 390, 330, 320, 301, 334, 390, 330, 320],
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        }
      }
    ]
  },
  //-----End Lead Option-----
  mentionPercentageOptions: {

    // Add timeline
    timeline: {
      axisType: 'category',
      left: 0,
      right: 0,
      bottom: 0,
      label: {
        fontFamily: 'Roboto, Arial, Verdana, sans-serif',
        fontSize: 11,
      },
      data: [
        '2014-01-01', '2014-02-01', '2014-03-01', '2014-04-01', '2014-05-01',
        {
          name: '2014-06-01',
          symbol: 'emptyStar2',
          symbolSize: 8
        },
        '2014-07-01', '2014-08-01', '2014-09-01', '2014-10-01', '2014-11-01',
        {
          name: '2014-12-01',
          symbol: 'star2',
          symbolSize: 8
        }
      ],
      autoPlay: true,
      playInterval: 3000
    },

    options: [{

        // Colors
        color: [
          '#3498db', '#e74c3c'
        ],

        // Global text styles
        textStyle: {
          fontFamily: 'Roboto, Arial, Verdana, sans-serif',
          fontSize: 13
        },


        // Add tooltip
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0,0.75)',
          padding: [10, 15],
          textStyle: {
            fontSize: 13,
            fontFamily: 'Roboto, sans-serif',
            color: '#fff',
          },
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },

        // Add legend
        legend: {
          orient: 'vertical',
          top: 'center',
          left: 0,
          data: ['Positive', 'Negative'],
          itemHeight: 8,
          itemWidth: 8
        },

        // Add series
        series: [{
          name: 'Mention',
          type: 'pie',
          center: ['50%', '50%'],
          radius: '60%',
          itemStyle: {
            borderWidth: 1,
            borderColor: '#fff',
          },
          data: [{
              value: 128 + 80,
              name: 'Positive'
            },
            {
              value: 8 + 1280,
              name: 'Negative'
            }
          ]
        }]
      },


      {
        series: [{
          name: 'Mention',
          type: 'pie',
          data: [{
              value: 128 + 80,
              name: 'Positive'
            },
            {
              value: 8 + 1280,
              name: 'Negative'
            }
          ]
        }]
      }
    ]
  },
   //-----End Mention Percentage Option-----
  userSummaryOptions: {
    // Define colors
    color: ["#A62B85", "#2F4EA8", '#1B9FF1'],
    // Global text styles
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },
    // Chart animation duration
    animationDuration: 750,
    // Setup grid
    grid: {
      left: 0,
      right: 40,
      top: 35,
      bottom: 60,
      containLabel: true
    },

    // Add legend
    legend: {
      data: ['Instagram', 'Twitter', 'Facebook'],
      itemHeight: 8,
      itemGap: 20
    },

    // Add tooltip
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: '#fff'
      }
    },

    // Horizontal axis
    xAxis: [{
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      data: ['Jan 2020', 'Feb 2020', 'Mar 2020', 'Apr 2020', 'Mei 2020', 'Jun 2020', 'Jul 2020', 'Aug 2020',
        'Sep 2020', 'Okt 2020', 'Nov 2020', 'Des 2019', 'Jan 2019', 'Feb 2019', 'Mar 2019', 'Apr 2019',
        'Mei 2019', 'Jun 2019', 'Jul 2019', 'Aug 2019', 'Sep 2019', 'Okt 2019', 'Nov 2019', 'Des 2019',
        '2017/3/1分红40万', '2017/3/2', '2017/3/3', '2017/3/6', '2017/3/7'
      ]
    }],

    // Vertical axis
    yAxis: [{
      type: 'value',
      axisLabel: {
        formatter: '{value} ',
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        lineStyle: {
          color: ['#eee']
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
        }
      }
    }],

    // Zoom control
    dataZoom: [{
        type: 'inside',
        start: 30,
        end: 70
      },
      {
        show: true,
        type: 'slider',
        start: 30,
        end: 70,
        height: 40,
        bottom: 0,
        borderColor: '#ccc',
        fillerColor: 'rgba(0,0,0,0.05)',
        handleStyle: {
          color: '#585f63'
        }
      }
    ],

    // Add series
    series: [{
        name: 'Instagram',
        type: 'line',
        smooth: true,
        symbolSize: 6,
        itemStyle: {
          borderWidth: 2,
        },
        data: [152, 156, 479, 442, 654, 835, 465, 704, 643, 136, 791, 254, 688, 119, 948, 316, 612, 378, 707,
          404, 485, 226, 754, 142, 965, 364, 887, 395, 838, 113, 662
        ]
      },
      {
        name: 'Twitter',
        type: 'line',
        smooth: true,
        symbolSize: 6,
        itemStyle: {
          borderWidth: 2,
        },
        data: [677, 907, 663, 137, 952, 408, 976, 772, 514, 102, 165, 343, 374, 744, 237, 662, 875, 462, 409,
          259, 396, 744, 359, 618, 127, 596, 161, 574, 107, 914, 708
        ]
      },
      {
        name: 'Facebook',
        type: 'line',
        smooth: true,
        symbolSize: 6,
        itemStyle: {
          borderWidth: 2,
        },
        data: [606, 919, 108, 691, 424, 196, 328, 136, 754, 427, 544, 983, 547, 834, 452, 576, 343, 168, 462,
          756, 344, 226, 511, 304, 648, 339, 655, 336, 605, 157, 864
        ]
      }
    ]
  },
   //-----End User Summary Option-----
  bestTimeOptions: {

    // Global text styles
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },

    // Chart animation duration
    animationDuration: 750,

    // Setup grid
    grid: {
      left: 0,
      right: 10,
      top: 0,
      bottom: 0,
      containLabel: true
    },

    // Add tooltip
    tooltip: {
      trigger: 'item',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: 'white',
      },
      axisPointer: {
        type: 'cross',
        lineStyle: {
          type: 'dashed',
          width: 1
        }
      },
      formatter: function (params) {
        return '<div class="mb-1">' + days[params.value[1]] + '</div>' + '<div class="mr-3">Commits: ' + params.value[2] + '</div>' + '<div class="mr-3">Time: ' + hours[params.value[0]] + '</div>';
      }
    },

    // Horizontal axis
    xAxis: [{
      type: 'category',
      data: hours,
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#eee',
          type: 'dashed'
        }
      }
    }],

    // Vertical axis
    yAxis: [{
      type: 'category',
      data: days,
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eee']
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.015)']
        }
      }
    }],

    // Add series
    series: [{
      name: 'Punch Card',
      type: 'scatter',
      symbolSize: function (val) {
        return val[2] * 2;
      },
      data: data
    }]
  },
   //-----End Bestime Option-----
  visitorOptions: {

    // Define colors
    color: ['#2ec7c9', '#b6a2de', '#5ab1ef', '#ffb980', '#d87a80'],

    // Global text styles
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },

    // Chart animation duration
    animationDuration: 750,

    // Setup grid
    grid: {
      left: 10,
      right: 40,
      top: 35,
      bottom: 0,
      containLabel: true
    },

    // Add legend
    legend: {
      data: ['Visitor'],
      itemHeight: 8,
      itemGap: 20
    },

    // Add tooltip
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif'
      },
      formatter: function (params) {
        return params[0].name + '<br/>' +
          params[0].seriesName + ': ' + params[0].value + ' (m^3/s)<br/>' +
          params[1].seriesName + ': ' + -params[1].value + ' (mm)';
      }
    },

    // Horizontal axis
    xAxis: [{
      type: 'category',
      boundaryGap: false,
      data: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        onZero: false,
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#eee',
          type: 'dashed'
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
        }
      }
    }],

    // Vertical axis
    yAxis: [{
        name: '',
        type: 'value',
        max: 500,
        axisLabel: {
          color: '#333'
        },
        axisLine: {
          lineStyle: {
            color: '#999'
          }
        },
        splitLine: {
          lineStyle: {
            color: '#eee'
          }
        },
      },
      {
        name: '',
        type: 'value',
        axisLabel: {
          color: '#333',
          formatter: function (v) {
            return -v;
          }
        },
        axisLine: {
          lineStyle: {
            color: '#999'
          }
        },
        splitLine: {
          lineStyle: {
            color: '#fff'
          }
        }
      }
    ],

    // Add series
    series: [{
      name: 'Flow',
      type: 'line',
      areaStyle: {
        normal: {
          opacity: 0.25
        }
      },
      smooth: true,
      symbolSize: 7,
      itemStyle: {
        normal: {
          borderWidth: 2
        }
      },
      data: [100, 200, 240, 180, 90, 200, 130]
    }]
  },
   //-----End Visitor Option-----
  engagementOptions: {

    // Global text styles
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },

    // Add tooltip
    tooltip: {
      trigger: 'item',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: 'white'
      }
    },

    // Add legend
    legend: {
      orient: 'vertical',
      top: 0,
      left: 0,
      data: ['Instagram', 'Facebook', 'Twitter'],
      itemHeight: 8,
      itemWidth: 8
    },

    // Setup polar coordinates
    radar: [{
      radius: '84%',
      center: ['50%', '50%'],
      name: {
        color: '#777'
      },
      indicator: [{
          text: 'Reach',
          max: 100
        },
        {
          text: 'Like',
          max: 100
        },
        {
          text: 'Comment',
          max: 100
        },
        {
          text: 'Share',
          max: 100
        }
      ],
    }],

    // Add series
    series: [{
      name: 'Budget vs spending',
      type: 'radar',
      symbolSize: 7,
      itemStyle: {
        normal: {
          borderWidth: 1
        }
      },
      lineStyle: {
        normal: {
          width: 1
        }
      },
      areaStyle: {
        normal: {
          opacity: 0.5
        }
      },
      data: [{
          value: [97, 68, 88, 94, 90, 86],
          name: 'Instagram'
        },
        {
          value: [97, 68, 38, 57, 90, 86],
          name: 'Facebook'
        },

        {
          value: [97, 68, 28, 94, 90, 86],
          name: 'Twitter'
        }
      ]
    }]
  },
   //-----End Engagment Option-----
  submittedLeadOptions: {

    // Define colors
    color: ['#7CCE7A'],

    // Global text styles
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },

    // Chart animation duration
    animationDuration: 750,

    // Setup grid
    grid: {
      left: 0,
      right: 40,
      top: 35,
      bottom: 0,
      containLabel: true
    },

    // Add legend
    legend: {
      data: ['Positif'],
      itemHeight: 8,
      itemGap: 20
    },

    // Add tooltip
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif'
      }
    },

    // Horizontal axis
    xAxis: [{
      type: 'category',
      boundaryGap: false,
      data: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#eee',
          type: 'dashed'
        }
      }
    }],

    // Vertical axis
    yAxis: [{
      type: 'value',
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        lineStyle: {
          color: '#eee'
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
        }
      }
    }],

    // Add series
    series: [{
      name: '',
      type: 'line',
      data: [10, 12, 21, 23, 20, 21, 30, 32, 21, 34, 23, 12],
      areaStyle: {
        normal: {
          opacity: 0.25
        }
      },
      smooth: true,
      symbolSize: 7,
      itemStyle: {
        normal: {
          borderWidth: 2
        }
      }
    }]
  },
   //-----End SubmitLead Option-----
  leadReportOptions: {

    // Global text styles
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },

    // Chart animation duration
    animationDuration: 750,

    // Setup grid
    grid: {
      left: 0,
      right: 20,
      top: 35,
      bottom: 0,
      containLabel: true
    },

    // Add legend
    legend: {
      data: ['Hot', 'Medium', 'Cold', 'Default'],
      itemHeight: 8,
      itemGap: 20
    },

    // Add tooltip
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: 'white'
      }
    },

    // Horizontal axis
    xAxis: [{
      type: 'category',
      boundaryGap: false,
      data: [
        'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'
      ],
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        lineStyle: {
          color: ['#eee']
        }
      }
    }],

    // Vertical axis
    yAxis: [{
      type: 'value',
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        lineStyle: {
          color: ['#eee']
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
        }
      }
    }],

    // Add series
    series: [{
        name: 'Hot',
        type: 'line',
        stack: 'Total',
        smooth: true,
        symbolSize: 7,
        data: [120, 132, 101, 134, 90, 230, 210, 101, 134, 90, 230, 210],
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        }
      },
      {
        name: 'Medium',
        type: 'line',
        stack: 'Total',
        smooth: true,
        symbolSize: 7,
        data: [220, 182, 191, 234, 290, 330, 310, 191, 234, 290, 330, 310],
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        }
      },
      {
        name: 'Cold',
        type: 'line',
        stack: 'Total',
        smooth: true,
        symbolSize: 7,
        data: [150, 232, 201, 154, 190, 330, 410, 201, 154, 190, 330, 410],
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        }
      },
      {
        name: 'Default',
        type: 'line',
        stack: 'Total',
        smooth: true,
        symbolSize: 7,
        data: [320, 332, 301, 334, 390, 330, 320, 301, 334, 390, 330, 320],
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        }
      }
    ]
  },
  //-----End Lead Report Option-----

  sentimentCompositionOptions: {
    // Define colors
    color: ['#7CCE7A', '#FF414E', '#a7a7a7',],
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },
    animationDuration: 750,
    grid: {
      left: 0,
      right: 30,
      top: 35,
      bottom: 0,
      containLabel: true
    },
    legend: {
      data: ['Positif', 'Negatif', 'Netral'],
      itemHeight: 8,
      itemGap: 20,
      textStyle: {
        padding: [0, 5]
      }
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: '#fff',
      },
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          color: 'rgba(0,0,0,0.025)'
        }
      }
    },
    xAxis: [{
      type: 'category',
      axisLabel: {
        color: '#333'
      },
      data: ['Web', 'Twitter', 'Instagram', 'Facebook', 'YouTube']
    }],
    yAxis: [{
      type: 'value',
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eee']
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.015)']
        }
      }
    }],

    // Add series
    series: [
      {
        name: 'Positif',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: [1200, 832, 724, 430, 1290]
      },
      {
        name: 'Negatif',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: [740, 960, 1012, 934, 640]
      },
      {
        name: 'Netral',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: [613, 420, 901, 560, 873]
      },
    ]
  },
  
  mostActiveSocmedOptions: {
    // Define colors
    color: ['#7CCE7A', '#FF414E', '#a7a7a7',],
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },
    animationDuration: 750,
    grid: {
      left: 0,
      right: 30,
      top: 35,
      bottom: 0,
      containLabel: true
    },
    legend: {
      data: ['Positif', 'Negatif', 'Netral'],
      itemHeight: 8,
      itemGap: 20,
      textStyle: {
        padding: [0, 5]
      }
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: '#fff',
      },
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          color: 'rgba(0,0,0,0.025)'
        }
      }
    },
    yAxis: [{
      type: 'category',
      axisLabel: {
        color: '#333'
      },
      data: ['Twitter', 'Instagram', 'Facebook', 'YouTube']
    }],
    xAxis: [{
      type: 'value',
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eee']
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.015)']
        }
      }
    }],

    // Add series
    series: [
      {
        name: 'Positif',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: [832, 724, 430, 1290]
      },
      {
        name: 'Negatif',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: [960, 1012, 934, 640]
      },
      {
        name: 'Netral',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: [420, 901, 560, 873]
      },
    ]
  },
  mostActiveWebsiteOptions: {
    // Define colors
    color: ['#7CCE7A', '#FF414E', '#a7a7a7',],
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },
    animationDuration: 750,
    grid: {
      left: 0,
      right: 30,
      top: 35,
      bottom: 0,
      containLabel: true
    },
    legend: {
      data: ['Positif', 'Negatif', 'Netral'],
      itemHeight: 8,
      itemGap: 20,
      textStyle: {
        padding: [0, 5]
      }
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: '#fff',
      },
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          color: 'rgba(0,0,0,0.025)'
        }
      }
    },
    yAxis: [{
      type: 'category',
      axisLabel: {
        color: '#333'
      },
      data: ['kompas.com', 'detik.com', 'kumparan.com', 'opini.id']
    }],
    xAxis: [{
      type: 'value',
      axisLabel: {
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eee']
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.015)']
        }
      }
    }],

    // Add series
    series: [
      {
        name: 'Positif',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: [832, 724, 430, 1290]
      },
      {
        name: 'Negatif',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: [960, 1012, 934, 640]
      },
      {
        name: 'Netral',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: [420, 901, 560, 873]
      },
    ]
  },
  mostInfluentialWebsiteOptions: {
    // Define colors
    color: ['#3498db',],
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },
    animationDuration: 750,
    grid: {
      left: 0,
      right: 30,
      top: 35,
      bottom: 0,
      containLabel: true
    },
    legend: {
      data: ['Pembaca per Bulan'],
      itemHeight: 8,
      itemGap: 20,
      textStyle: {
        padding: [0, 5]
      }
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: '#fff',
      },
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          color: 'rgba(0,0,0,0.025)'
        }
      }
    },
    yAxis: [{
      type: 'category',
      inverse: true,
      axisLabel: {
        color: '#333'
      },
      data: ['okezone.com', 'tribunnews.com', 'pikiran-rakyat.com', 'kompas.com', 'detik.com', 'grid.id', 'kumparan.com', 'suara.com', 'liputan6.com', 'merdeka.com', 'jawapos.com', 'sindonews.com', 'cnnindonesia.com', 'idntimes.com']
    }],
    xAxis: [{
      type: 'value',
      axisLabel: {
        color: '#333',
        formatter: value => {
          return value/1000000 + 'jt'
        },
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eee']
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.015)']
        }
      }
    }],

    // Add series
    series: [
      {
        name: 'Pembaca per Bulan',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: [19600000, 17000000, 16000000, 15500000, 12750000, 11399000, 10400753, 9700720, 8400341, 6543700, 5674760, 4451720, 3372890, 2705609, 1099743, 964561,]
      },
    ]
  },
  
  //-----End Sentimern Option-----
  sentimentTrendsOptions: {
    // Define colors
    color: ['#7CCE7A', '#FF414E', '#a7a7a7',],
    // Global text styles
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },
    // Chart animation duration
    animationDuration: 750,
    // Setup grid
    // grid: {
    //   left: 0,
    //   right: 40,
    //   top: 35,
    //   bottom: 60,
    //   containLabel: true
    // },

    // Add legend
    legend: {
      data: ['Positif', 'Negatif', 'Netral'],
      itemHeight: 8,
      itemGap: 20
    },

    // Add tooltip
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: '#fff'
      },
    },

    // Horizontal axis
    xAxis: [{
      name: 'Agustus 2021',
      nameLocation: 'center',
      nameGap: 32,
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        color: '#333',
        formatter: function (value) {
          return moment(value).locale('id').format('Do')
        }
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      data: [
        '2021-08-12', '2021-08-13', '2021-08-14', '2021-08-15', '2021-08-16', '2021-08-17', '2021-08-18',
      ]
    }],

    // Vertical axis
    yAxis: [{
      type: 'value',
      max: 200,
      axisLabel: {
        formatter: '{value} ',
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        lineStyle: {
          color: ['#eee']
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
        }
      }
    }],

    // Add series
    series: [
      {
        name: 'Positif',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        },
        data: [20, 42, 30, 100, 30, 120, 50]
      },
      {
        name: 'Negatif',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        },
        data: [50, 20, 60, 10, 30, 31, 12]
      },
      {
        name: 'Netral',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        },
        data: [120, 50, 60, 70, 110, 120, 107]
      },
    ]
  },
  weekCounterOptions: {
    // Define colors
    color: ['#a7a7a7',],
    // Global text styles
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },
    // Chart animation duration
    animationDuration: 750,
    // Setup grid
    // grid: {
    //   left: 0,
    //   right: 40,
    //   top: 35,
    //   bottom: 60,
    //   containLabel: true
    // },

    // Add legend
    legend: {
      data: ['Counter'],
      itemHeight: 8,
      itemGap: 20
    },

    // Add tooltip
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: '#fff'
      },
    },

    // Horizontal axis
    xAxis: [{
      name: 'Agustus 2021',
      nameLocation: 'center',
      nameGap: 32,
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        color: '#333',
        formatter: function (value) {
          return moment(value).locale('id').format('Do')
        }
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      data: [
        '2021-08-12', '2021-08-13', '2021-08-14', '2021-08-15', '2021-08-16', '2021-08-17', '2021-08-18',
      ]
    }],

    // Vertical axis
    yAxis: [{
      type: 'value',
      max: 40,
      boundaryGap: false,
      axisLabel: {
        formatter: '{value} ',
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        lineStyle: {
          color: ['#eee']
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
        }
      }
    }],

    // Add series
    series: [
      {
        name: 'Counter',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        },
        data: [6, 8, 4, 7, 17, 10, 4]
      },
    ]
  },
  negativeSentimentOptions: {
    // Define colors
    color: ['#FF414E'],
    // Global text styles
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },
    // Chart animation duration
    animationDuration: 750,

    // Add legend
    legend: {
      data: ['Negatif'],
      itemHeight: 8,
      itemGap: 20
    },

    // Add tooltip
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        color: '#fff'
      },
    },

    // Horizontal axis
    xAxis: [{
      name: 'Agustus 2021',
      nameLocation: 'center',
      nameGap: 32,
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        color: '#333',
        formatter: function (value) {
          return moment(value).locale('id').format('Do')
        }
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      data: [
        '2021-08-12', '2021-08-13', '2021-08-14', '2021-08-15', '2021-08-16', '2021-08-17', '2021-08-18',
      ]
    }],

    // Vertical axis
    yAxis: [{
      type: 'value',
      max: 200,
      axisLabel: {
        formatter: '{value} ',
        color: '#333'
      },
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      splitLine: {
        lineStyle: {
          color: ['#eee']
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
        }
      }
    }],

    // Add series
    series: [
      {
        name: 'Negatif',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          normal: {
            borderWidth: 2
          }
        },
        data: [50, 20, 60, 10, 30, 31, 12]
      },
    ]
  },
}

export default chartDatas